@charset "UTF-8";
@import "~sanitize.css";


// Variables
$textGray: #999;
$textDarkGray: #666;
$textOrange: #e87d00;
$borderGray: #ccc;
$borderDarkGray: #999;
$borderYellow: #ffdf5a;
$backgroundGray: #f5f5f7;
$backgroundDarkGray: #e3e3e3;
$backgroundYellow: #ffdf5a;
$backgroundLightYellow: #fff9d6;
$activeMenuGray: #666;
$buttonGray: #9393a2;
$buttonBlack: #48484d;

$defaultVerticalMargin: 4rem;
$breakPoint: 1024px;


// mixin
@mixin headlineTextStyle() {
  .main_headline {
    font-size: 1.5rem;
  }
  .sub_headline {
    margin: .5rem 0 0 0;
    font-size: 1rem;
    font-weight: 400;
  }
}

@mixin moreButton() {
  text-align: center;
  &>* {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 4rem;
    background: red;
    border-radius: 40px;
    text-decoration: none;
    color: $textDarkGray;
    background: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    font-size: .75rem;
    font-weight: bold;
    position: relative;

    &:after {
      content: ">";
      position: absolute;
      right: 20px;
    }

    &:hover {
      background: $buttonGray;
      color: #fff;
    }
  }
}

@mixin toppageContent($prefix, $list_width: 33.333% ) {
  // padding: 40px 0;
  padding: 0;
  @media (max-width: $breakPoint) {
    width: 100%;
  }

  h2 {
    text-align: center;
    margin: 0 0 20px;
    @include headlineTextStyle
  }
  p {
    text-align: center;
  }

  .#{$prefix}_list {
    display: flex;
    flex-wrap: wrap;
    width: 1025px;
    margin: $defaultVerticalMargin auto;
    @media (max-width: $breakPoint) {
      display: block;
      width: 100%;
    }

    .#{$prefix}_item {
      position: relative;
      width: $list_width;
      @media (max-width: $breakPoint) {
        width: 100vw;
      }
      
      

      a {
        color: $textDarkGray;
        text-decoration: none;
      }
      
      img {
        width: 100%;
      }

      .#{$prefix}_item_wrapper {
        display: block;
        margin: 1rem;
        background: #fff;
      }
      .#{$prefix}_name {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .lesson_name_jp {
          color: #fff;
          font-size: 1.75rem;
          font-weight: 600;
        }

        .lesson_name_en {
          color: #fff;
          font-size: 1.375rem;
        }
      }
    }
  }

  .#{$prefix}_more {
    @include moreButton();
  }
}


@mixin topPageBackground($bg_color) {
  width: 100%;
  // height: 368px;
  height: 15vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $bg_color;
  @media (max-width: $breakPoint) {
    height: 18vw;
  }
}

// Original reset css
html, body {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $textDarkGray;
  cursor: auto;
}
ul {
  list-style: none;
  padding: 0;
}
img {
  max-width: 100%;
}

// header
header {
  padding: 0 0 0 2rem;
  overflow-x: scroll;
  border-bottom: 1px solid $borderGray;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
  }
  @media (max-width: $breakPoint) {
    padding: 1rem 0 0;
  }
  .logo {
    display: flex;
    padding: 24px 0 0 0;
    .flex_grow {
      flex-grow: 1;
    }
    a {
      text-decoration: none;
      img {
        @media (max-width: $breakPoint) {
          max-height: 48px;
          padding-left: 18px;
        }
      }
    }
    .nav_contact {
      align-self: flex-end;
      text-align: right;
      width: 62px;
      height: 62px;
      display: inline-block;
      background-repeat: no-repeat;
      margin: 0 20px 0 0;
      transition: all 300ms 0s ease;
      @media (max-width: $breakPoint) {
        transition: none 0s 0s ease;
      }

      &:hover {
        width: 172px;
        @media (max-width: $breakPoint) {
          width: 62px;
        }
      }
    }
  }
  nav {
    @media (max-width: $breakPoint) {
      width: 100vw;
      overflow-x: scroll;
    }

    ul {
      display: flex;
      margin-bottom: 0;
      @media (max-width: $breakPoint) {
        width: $breakPoint; // memo 再考の余地あり
      }

      li {
        margin: 0 1rem;
        margin-bottom: 0;
        border-bottom: 2px solid #fff;
        @media (max-width: $breakPoint) {
          font-size: .875rem;
          padding: .35rem 0;
        }

        &:hover {
          border-bottom: 2px solid $borderDarkGray;
        }

        a {
          color: $textGray;
          text-decoration: none;
        }
      }
    }
  }
  .navigation_active {
    font-weight: bold;
    border-bottom: 2px solid $borderDarkGray;
    a {
      color: $textDarkGray;
    }
  }
}
.breadcrumb_list {
  padding: 1rem 0 1rem 2rem;
  background: $backgroundGray;
  @media (max-width: $breakPoint) {
    padding: 0.5rem 0 0.5rem 1rem;
  }
  a {
    color: $activeMenuGray;
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
  }
}

// toppage
.index_title {
  max-width: 400px;
  width: 90%;
  // margin: $defaultVerticalMargin auto;
  margin: 32px auto;
  padding: 1.75rem 0;
  text-align: center;
  border-top: 1px solid $borderDarkGray;
  border-bottom: 1px solid $borderDarkGray;

  .sub_title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .main_title {
    font-size: 2rem;
  }
}

.articles_toppage {
  @include toppageContent("article");
  background-color: $backgroundGray;
  padding-top: 40px;
  @media (max-width: $breakPoint) {
    padding-bottom: 40px;
  }
}
.lessons_toppage {
  @include toppageContent("lesson");
  background-color: $backgroundYellow;
  @media (max-width: $breakPoint) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.shops_toppage {
  @include toppageContent("shop");
  @media (max-width: $breakPoint) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .shops_scroll {
    height: 238px;
    @media (max-width: $breakPoint) {
      height: auto;
    }

    .shop_scroll_list {
      display: flex;
      width: 100vw;
      @media (max-width: $breakPoint) {
        width: $breakPoint; // memo 再考の余地あり
      }

      a {
        display: block;
        width: 100%;
        position: relative;
        .thumbnail_shop_info {
          position: absolute;
          background: rgba(0, 0, 0, .4);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: #fff;
          display: flex;
          align-items: flex-start;
          transition: 0.2s ease-in;
          padding-bottom: 10px;
          @media (max-width: $breakPoint) {
            align-items: flex-end;
          }
          .thumbnail_shop_name {
            font-size: 1.25rem;
            display: flex;
            width: 100%;
            margin: 1rem;
            padding-top: 9.5vw;
            transition: 0.2s ease-in;
            .flex_grow {
              flex-grow: 1;
            }
          }
        }
      }
      img {
        width: 100%;
        @media (max-width: $breakPoint) {
          min-width: 50vw;
        }
      }
      .swiper-slide {
        @media (max-width: $breakPoint) {
          min-width: 50vw !important;
        }
      }
    }
  }
}
.ads_toppage {
  @include toppageContent("ads", 25%);
  @media (max-width: $breakPoint) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.ads_toppage_wrapper {
  background: $backgroundGray;
}
.contact_toppage {
  @include toppageContent("contact");
  @media (max-width: $breakPoint) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  background-color: $backgroundLightYellow;
  .contact_more>a {
      background: $backgroundYellow;
      &:hover {
        color: #fff;
        background: $buttonBlack;
      }
    }

}

.lessons_toppage_start_bg {
  @include topPageBackground($backgroundYellow);
}
.lessons_toppage_end_bg {
  @include topPageBackground(#fff);
}
.shops_toppage_start_bg {
  @include topPageBackground($backgroundGray);
}
.contact_toppage_start_bg {
  @include topPageBackground($backgroundLightYellow);
}
.contact_toppage_end_bg {
  @include topPageBackground($backgroundGray);
}
.shops_toppage_end_bg {
  @include topPageBackground($backgroundGray);
}

footer {
  padding: 80px 0 24px;
  text-align: center;
  background: $backgroundGray;

  .nintei_pc {
    margin: 0 auto 40px;
    display: block;
    @media (max-width: $breakPoint) {
      display: none;
    }
  }
  .nintei_sp {
    margin: 0 auto 40px;
    display: none;
    @media (max-width: $breakPoint) {
      display: block;
    }
  }

  .footer_logo {
    width: 170px;
  }

  small {
    display: block;
    padding: 24px 0 0;
    text-align: center;
  }
}


.lessons_toppage {
  .lesson_list_style_for_sp {
    @media (max-width: $breakPoint) {
      display: flex;
      flex-wrap: wrap;
      .lesson_item {
        width: 50%;
        .lesson_name_jp {
          font-size: 1.375rem !important;
        }
        .lesson_name_en {
          font-size: 1rem !important;
        }
      }
    }
  }
}

.lesson_item {
  &::before {
    content: '';
    display: block;
    width: 93.5%;
    height: 87.5%;
    position: absolute;
    border-radius: 5px;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover::before {
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: rgba(0, 0, 0, 0);
  }
}




// template-list-page

.list_container {
  background: $backgroundGray;
}
.list_headline {
  margin: 0;
  text-align: center;
}

.category_scroll {
  width: 100vw;
  overflow-x: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
  }
}
.category_navigation {
  display: flex;
  justify-content: center;
  width: 1025px;
  margin: 0 auto;
  font-size: .875rem;
  @media (max-width: $breakPoint) {
    justify-content: flex-start;
  }

  .active {
    background: $buttonGray !important;
    color: #fff !important;
  }

  .selected_active {
    background: $buttonGray !important;
    color: #fff !important;
  }

  .parent_category_list_item {
    margin: 1rem;
  }
  .parent_category_link, .active_child {
    display: block;
    border: 1px solid $borderDarkGray;
    padding: 0 1.25rem;
    position: relative;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    color: $textDarkGray;
    background: #fff;
    text-decoration: none;
    &:hover {
      background-color: #cacbe0;
    }
  }

  .active_child {
    position: absolute;
    background: $buttonGray !important;
    color: #fff !important;
    margin-top: 20px;
    z-index: 0;
  }

  .child_category_list {
    position: absolute;
    display: flex;
    background: #fff;
    border: 1px solid $borderGray;
    margin: 20px 0 0 0;
    padding: 0 2rem;
    border-radius: 8px;
    z-index: 1;
    flex-wrap: wrap;

    @media (max-width: $breakPoint) {
      flex-direction: column;
    }

    .drawer_text {
      position: relative;
      z-index: -999;
    }


    &:before {
      content: "";
      position: absolute;
      top: -26px;
      left: 40px;
      margin-left: -16px;
      border: 13px solid transparent;
      border-bottom: 13px solid #FFF;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: -29px;
      left: 40px;
      margin-left: -17px;
      border: 14px solid transparent;
      border-bottom: 14px solid $borderGray;
      z-index: 1;
    }

    .child_category_list_item {
      padding: 1rem;
      a {
        color: $textGray;
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }
}


.article_list {
  display: flex;
  width: 1025px;
  margin: 0 auto;
  padding: 60px 0 0 0;
  @media (max-width: $breakPoint) {
    display: block;
    width: 100vw;
  }

  flex-wrap: wrap;

  .column-3 {
    @media (min-width: $breakPoint) {
      width: 33% !important;
    }
    @media (max-width: $breakPoint) {
      display: block;
      width: 100%;
    }
  }

  .article_list_item {
    width: 25%;
    height: 320px;
    margin: 1rem 0;
    @media (max-width: $breakPoint) {
      width: 100%;
      height: auto;
    }

    .article_list_item_inner {
      margin: 0 1rem;
      background: #fff;
      height: 100%;
      
      .article_list_item_content {
        display: flex;
        flex-direction: column;
        color: $textGray;
        text-decoration: none;
        height: 100%;
        @media (max-width: $breakPoint) {
          display: flex;
          width: 100%;
          height: auto;
          .sp_flex_grow {
            flex-grow: 1;
            width: calc(100% - 120px);
          }
          .sp_flex_row_image {
            height: 100% !important;
            display: block;
          }
        }

        h2 {
          margin: 1rem;
          font-size: 1rem;
          font-weight: normal;
          flex-grow: 1;
        }
        
        .article_list_item_category {
          margin: 1rem;
          font-size: .75rem;
        }
      }
    }
    #sp_flex_row {
      @media (max-width: $breakPoint) {
        flex-direction: row;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .article_thumbnail {
    height: 170px;
    @media (max-width: $breakPoint) {
      height: auto;
    }
    img {
      object-fit: cover;
    }
  }

  .recommend_thumbnail {
    @media (max-width: $breakPoint) {
      max-width: 120px !important;
      overflow: hidden;
    }
  }


}

.free_article_list {
  .article_list_item {
    height: 120px;
    width: 50%;
    @media (max-width: $breakPoint) {
      width: 100%;
      height: auto;
    }
  }
}

.pagenation {
  text-align: center;
  padding: 40px 0;

  .page-numbers {
    display: inline-block;
    margin: 0 .25rem;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid $borderGray;
  }

  .prev, .next {
    border: none;
  }

  .current {
    background: $borderDarkGray;
    color: $backgroundGray;
  }

  a {
    text-decoration: none;
    color: $textGray;
  }

}




// template-school-page

.school_list {
  background: $backgroundGray;

  .selected {
    background: $borderDarkGray !important;
    color: #fff !important;
  }
  div#special_button {
    width: 1025px;
    margin: 40px auto -41px;
    border: 1px solid $borderGray;
    background: #fff;
    font-size: 1rem;
    text-align: center;
    padding: .25rem;
    color: $textGray;
    cursor: pointer;

    @media (max-width: $breakPoint) {
      width: 100vw;
    }
  }

  .school_navigation_list {
    display: flex;
    width: 1025px;
    margin: 40px auto;
    border: 1px solid $borderGray;
    background: #fff;

    @media (max-width: $breakPoint) {
      width: 100vw;
    }

    li {
      flex-grow: 1;
      padding: .25rem 0;
      text-align: center;
      color: $textGray;
      border-right: 1px solid $borderGray;
      cursor: pointer;
    }
    li:last-of-type {
      border-right: none;
    }

  }
  .navigation_wrap {
    @media (max-width: $breakPoint) {
      flex-wrap: wrap;
      li {
        width: 50%;
        border: 1px solid $borderGray;
      }
    }
  }

  .list_data {
    width: 1025px;
    margin: 40px auto 0;
    @media (max-width: $breakPoint) {
      width: 100vw;
    }

    h2 {
      margin: 40px 0 0;
      padding: .25rem 0 .25rem 1rem;
      font-size: 1rem;
      background: #ffdf5a;
    }
    ul {
      margin: 0;
      border: 2px solid #ffdf5a;
    }
    li {
      padding: 1rem 0 1rem 1rem;
      background: #fff;
    }
    li:nth-child(even){
      background: $backgroundGray;
    }

    .school_name {
      color: #e87d00;
      text-decoration: underline;
      margin: .5rem 0;
    }
    .school_address {
      margin: .5rem 0;
    }
    .school_tel {
      margin: .5rem 0;
    }

  }
}


// ゴマミとは
.aboutus {
  background: $backgroundGray;
  padding: 40px 0;

  .member {
    width: 1025px;
    padding: 64px;
    margin: 0 auto 40px;
    border: 2px solid $borderDarkGray;
    position: relative;
    @media (max-width: $breakPoint) {
      width: 90vw;
      padding: 55px 35px 0;
    }

    &>img {
      background: $backgroundGray;
      padding: 0 20px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .member_row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    @media (max-width: $breakPoint) {
      display: block;
    }

    &>div {
      width: 30%;
      @media (max-width: $breakPoint) {
        width: 100%;
        margin-bottom: 4rem;
      }
    }

    img {
      height: 140px;
    }
    strong {
      display: block;
      margin: 1rem 0;
    }
  }

  .activity_wrapper {
    background: #fff;
    margin: 60px auto;
    padding: 0 0 80px;
  }
  .activity {
    width: 1025px;
    margin: 0 auto;
    @media (max-width: $breakPoint) {
      width: 100vw;
    }

    &>img {
      margin: -20px 0 0 0;
      @media (max-width: $breakPoint) {
        margin: -20px 0 0 20px;
      }
    }

    section {
      display: flex;
      margin: 2rem 0;
      @media (max-width: $breakPoint) {
        display: block;
        width: 90vw;
        margin: 0 auto;
      }

      h2 {
        font-size: 1.25rem;
      }
      &>div {
        flex-grow: 1;
        margin: 0 64px 0 0;
        line-height: 1.5;
        @media (max-width: $breakPoint) {
          margin: 0 0 20px;
        }
      }
      &>img {
        width: 320px;
        @media (max-width: $breakPoint) {
          width: 100%;
        }
      }
    }
  }

  .message {
    text-align: center;
    padding: 80px 0;
    @media (max-width: $breakPoint) {
      display: block;
      width: 90vw;
      margin: 0 auto;
    }

    &>p {
      text-align: left;
      width: 860px;
      margin: 26px auto;
      line-height: 1.5;
      @media (max-width: $breakPoint) {
        width: 90vw;
        line-height: 1.75;
      }
    }
    & > img {
      margin-bottom: 30px;
    }
    strong {
      display: block;
      width: 860px;
      margin: 40px auto;
      text-align: right;
      @media (max-width: $breakPoint) {
        display: block;
        width: 90vw;
        margin: 0 auto;
      }
    }
  }
}




// 記事
.single_article {
  background: $backgroundGray;
  padding: 20px 0;
  @media (max-width: $breakPoint) {
    padding: 0;
  }

  .relation_label {
    text-align: center;
    margin: 40px 0;
  }

  .main_visual {
    width: 640px;
    min-height: 320px;
    margin: 0 auto;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(4deg, rgba(0,0,0,0.5676645658263305) 0%, rgba(0,0,0,0.30996148459383754) 30%, rgba(0,0,0,0) 100%);
      position: absolute;
    }

    @media (max-width: $breakPoint) {
      width: 100vw;
      min-height: 60px;
    }

    img {
      width: 100%;
    }
    .title {
      padding: 0 1rem 0 2rem;
      border-left: 5px solid #ffdf5a;
      position: absolute;
      color: #fff;
      bottom: 5%;
      left: 40px;
      line-height: 1.2;
      span {
        font-size: 0.875rem;
        line-height: 1.5;
        display: block;
        margin-bottom: 10px;
      }
      @media (max-width: $breakPoint) {
        padding: 0 1rem 0 1rem;
        bottom: 3%;
        left: 20px;
        line-height: 1;
        font-size: 1.5rem;
        span {
          font-size: 0.75rem;
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }

  .shop_information_wrapper {
    width: 600px;
    margin: 0 auto;
    padding: 16px;
    background: #fff;
    @media (max-width: $breakPoint) {
      width: 100%;
      max-width: 600px;
    }
  }
  .shop_information {
    padding: 2rem;
    background: #fff;
    border: 4px solid $borderYellow;
    @media (max-width: $breakPoint) {
      width: 100%;
      padding: 1rem 0.2rem;
    }
    
    h2 {
      padding-bottom: 16px;
      border-bottom: 2px solid $borderYellow;
      width: 100%;
      @media (max-width: $breakPoint) {
        font-size: 1.45rem;
      }
    }

    h2, h3 {
      margin: 0 20px 0 0;
      font-weight: bold;
    }
    div {
      margin: 1rem;
      display: flex;
      align-items: center;
      @media (max-width: $breakPoint) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      margin: 0;
    }
  }
  // 記事内容のスタイル
  .content {
    width: 640px;
    margin: 40px auto;
    @media (max-width: $breakPoint) {
      width: 100vw;
      margin: 20px auto;
    }
    &>* {
      @media (max-width: $breakPoint) {
        margin: 1rem;
      }
    }
    img {
      width: 100%;
      object-fit: contain;
      @media (max-width: $breakPoint) {
        height: auto;
      }
    }
  }
  .back_link {
    text-align: center;
    padding: 40px 0;
  }
}


.contact {
  background: $backgroundGray;
  padding: 0 0 80px;
  margin: 0 0 -5px 0;
  .contact_headline {
    text-align: center;
    padding: 40px 0;
  }
  .bottom_border {
    border-bottom: 1px solid $borderGray;
  }

  .form_row {
    width: 800px;
    margin: 0 auto;
    padding: 10px 0 30px;
    @media (max-width: $breakPoint) {
      width: 90vw;
    }

    .required {
      display: inline-block;
      font-size: .75rem;
      height: 1rem;
      line-height: 1rem;
      padding: 0 .5rem;
      background: red;
      color: #fff;
    }

    label {
      display: block;
      font-weight: 600;
      padding: 0 0 5px 0;
    }
    .form_content {
      display: flex;
    }
    .full_width_form {
      &>* {
        width: 100%;
        padding: .25rem .5rem;
      }
    }
    .tel_form {
      @media (max-width: $breakPoint) {
        display: flex;
        justify-content: space-between;
        width: 90vw;

        &>input {
          width: 30%;
        }
      }
    }
    .double_column_form {
      &>* {
        width: 48%;
        padding: .25rem .5rem;
        &:first-of-type {
          margin-right: 4%;
        }
      }
    }
    .notice_text {
      color: $textGray;
    }
  }

  .send_button {
    @include moreButton();
  }

}

#contact_form {
  height: 1px;
  overflow: hidden;
}


.hide {
  display: none !important;
}

#special_daycare_content {
  width: 1025px;
  margin: 0 auto;
  @media (max-width: $breakPoint) {
    width: 95vw;
  }

  h2 {
    span {
      border-bottom: 4px solid $borderYellow;
    }
  }

  .white_area {
    background: #fff;
    padding: 1rem 0;
    h3 {
      margin: 2rem 2rem 0 2rem;
      padding: 0 0 0 1rem;
      border-left: 28px solid $borderYellow;
    }
    p {
      margin: .5rem 2rem 2rem 2rem;
    }
    .action {
      margin: 1rem 2rem 2rem 2rem;
      color: $textOrange;
      text-decoration: underline;
    }
  }

  strong {
    display: block;
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  .list > p {
    margin: 1rem 0 1rem 1rem;
  }

  b {
    color: $textOrange;
    text-decoration: underline;
  }

  a {
    color: $textOrange;
    text-decoration: underline;
    font-weight: bolder;
  }

  .block_information {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 2rem 0 0 0;
    @media (max-width: $breakPoint) {
      display: block;
    }

    .block_info {
      width: 48%;
      @media (max-width: $breakPoint) {
        width: 100%;
        margin: 2rem 0;
      }
      padding: 2rem 0;
      background: $backgroundDarkGray;

      h4 {
        font-size: 1.25rem;
      }

      &>* {
        margin: 1rem;
      }

    }
  }
}

//個別に当てたスタイル
.ads_toppage .ads_list {
  margin: 0 auto;
  padding: 40px 0;
  .ads_item {
      padding: 16px;
  }
}

.snsButtons {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  @media (max-width: $breakPoint) {
    bottom: 0;
    right: 0;
  }

  a {
    display: block;
    text-decoration: none;
    margin: 0 0 24px 0;
    @media (max-width: $breakPoint) {
      margin: 0 24px 24px 0;
    }

    span {
      display: none;
      @media (max-width: $breakPoint) {
        display: inline;
        color: $textDarkGray;
        padding: 0 20px 0 0;
      }
    }
  }

  .sp_hide {
    display: block;
    @media (max-width: $breakPoint) {
      display: none;
    }
  }

  .open_button {
    display: none;
    @media (max-width: $breakPoint) {
      display: block;
    }
  }
}

.snsButtonsSPStyle {
  @media (max-width: $breakPoint) {
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .7);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}


.scroll_top_button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}


.ads_list {
  @media (max-width: $breakPoint) {
    display: flex !important;
    flex-wrap: wrap;
  }
  .ads_item {
    @media (max-width: $breakPoint) {
      width: 50% !important;
    }
  }
}

.thanks {
  background-color: #F5F5F7;
  padding-top: 60px;
  p {
    text-align: center;
    margin: 0 auto 60px;
  }
  .thanks_button {
    text-align: center;
    margin-top: 80px;

    a {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 6rem;
      background: red;
      border-radius: 40px;
      text-decoration: none;
      color: #666;
      background: #fff;
      box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
      font-size: 0.75rem;
      font-weight: bold;
      position: relative;
      &:after {
        content: ">";
        position: absolute;
        right: 20px;
      }
    }
  }
}


.single_article {
  .article_list {
    margin: 0 auto;
    padding: 0;
    flex-wrap: wrap;
  }

  .relation_label {
    margin-top: 80px;
  }
}

.articles_toppage {
  .article_list {
    margin: 0 auto 4rem;
    padding: 20px 0 0 0;
  }
}

.list_navigation {
  span {
    @media (max-width: $breakPoint) {
      display: none;
    }
  }
}
